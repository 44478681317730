// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset básico */
body {
  margin: 0;
  font-family: 'VT323', monospace !important;
  background-color: #000000;
  color: #ffffff;
  overflow-x: hidden;
  font-size: 1.6rem;
}

/* Contenedor principal */
.App {
  text-align: center;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

.widget, iframe, #podcast, .card, .btn, a {
  background-color: transparent !important;
  color: #0026ff !important;
  font-family: 'VT323', monospace !important;
  border: none !important;
}

a {
  text-decoration: none;
  color: #00ffff;
}

a:visited {
  color: #00ff00;
}

a:hover {
  color: #ffff00;
}

a:active {
  color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,SAAS;EACT,0CAA0C;EAC1C,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA,yBAAyB;AACzB;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC,yBAAyB;EACzB,0CAA0C;EAC1C,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* Reset básico */\nbody {\n  margin: 0;\n  font-family: 'VT323', monospace !important;\n  background-color: #000000;\n  color: #ffffff;\n  overflow-x: hidden;\n  font-size: 1.6rem;\n}\n\n/* Contenedor principal */\n.App {\n  text-align: center;\n  overflow-x: hidden;\n  width: 100%;\n  position: relative;\n}\n\n.widget, iframe, #podcast, .card, .btn, a {\n  background-color: transparent !important;\n  color: #0026ff !important;\n  font-family: 'VT323', monospace !important;\n  border: none !important;\n}\n\na {\n  text-decoration: none;\n  color: #00ffff;\n}\n\na:visited {\n  color: #00ff00;\n}\n\na:hover {\n  color: #ffff00;\n}\n\na:active {\n  color: #ff0000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
