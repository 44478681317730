import React, { useEffect, useRef, useState } from 'react';
import './TeletextoHeader.css';
import './teletext.css';

const Section = ({ colorClass, title, src, height, page }) => {
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState('300px');

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const updateHeight = () => {
        try {
          const height = iframe.contentWindow.document.body.scrollHeight;
          // Añadir un pequeño margen para evitar scroll
          setIframeHeight(`${height + 10}px`);
        } catch (e) {
          // Si hay error de seguridad por cross-origin, usamos el height prop
          setIframeHeight(height || '300px');
        }
      };

      iframe.onload = () => {
        // Pequeño retraso para asegurar que el contenido está cargado
        setTimeout(updateHeight, 100);
      };
      
      window.addEventListener('resize', updateHeight);

      return () => window.removeEventListener('resize', updateHeight);
    }
  }, [height]);

  return (
    <section className={`${colorClass} widget-container`} style={{ marginBottom: '0' }}>
      <h2 className="section-title">
        <span className="section-title-text">{title}</span>
        <span className={`page-number ${colorClass}`}>{page}</span>
      </h2>
      <div className="iframe-wrapper">
        <iframe
          ref={iframeRef}
          src={src}
          allowtransparency="true"
          title={title}
          style={{ 
            height: iframeHeight, 
            width: '100%', 
            border: 'none',
            display: 'block', // Evita espacio extra debajo del iframe
            marginBottom: '0'
          }}
        />
      </div>
    </section>
  );
};

export default Section;