import React, { useState, useEffect } from 'react';
import './TeletextoHeader.css';
import './teletext.css';

const RetroTV = () => {
    const [streamStatus, setStreamStatus] = useState({
        online: false,
        streamTitle: 'TV ESLA EKO',
        lastDisconnectTime: null
    });
    const [currentTime, setCurrentTime] = useState('');
    
    const TV_URL = "https://tv.eslaeko.net";
    
    useEffect(() => {
        const checkStreamStatus = async () => {
            try {
                const response = await fetch('/api/tv-status');
                const data = await response.json();
                
                setStreamStatus({
                    online: data.online,
                    streamTitle: data.streamTitle || 'TV ESLA EKO',
                    lastDisconnectTime: data.lastDisconnectTime
                });
            } catch (error) {
                console.error('Error checking stream status:', error);
                setStreamStatus(prev => ({ ...prev, online: false }));
            }
        };

        checkStreamStatus();
        const statusInterval = setInterval(checkStreamStatus, 30000);

        return () => clearInterval(statusInterval);
    }, []);

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            setCurrentTime(`${hours}:${minutes}`);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const getLastDisconnectText = () => {
        if (!streamStatus.lastDisconnectTime) return '';
        
        const disconnectDate = new Date(streamStatus.lastDisconnectTime);
        const hours = String(disconnectDate.getHours()).padStart(2, '0');
        const minutes = String(disconnectDate.getMinutes()).padStart(2, '0');
        return `ÚLTIMA EMISIÓN: ${hours}:${minutes}`;
    };

    const styles = {
        section: {
            width: '100%',
            padding: 0,
            background: 'transparent',
            position: 'relative',
            boxSizing: 'border-box',
            border: 'none',
            outline: 'none'
        },
        outerContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        tvContainer: {
            width: '776px',
            height: '321px',
            '@media (max-width: 800px)': {
                width: '100%',
                height: 'auto',
                aspectRatio: '776/321'
            }
        },
        tvInner: {
            height: '100%',
            background: '#000000',
            border: '2px solid rgb(117, 251, 253)',
            fontFamily: "'VT323', monospace",
            color: '#FFFF00',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
        },
        header: {
            height: '24px',
            background: '#000000',
            borderBottom: '1px solid rgb(117, 251, 253)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 10px',
            fontSize: '1.4rem'
        },
        headerLeft: {
            color: '#FFFFFF'
        },
        headerCenter: {
            color: '#FF0000',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '50%'
        },
        headerRight: {
            color: '#00FF00'
        },
        content: {
            flex: 1,
            position: 'relative'
        },
        iframe: {
            width: '100%',
            height: '100%',
            border: 'none'
        },
        offlineScreen: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 15%, #FFFF00 15%, #FFFF00 30%, #00FFFF 30%, #00FFFF 45%, #00FF00 45%, #00FF00 60%, #FF00FF 60%, #FF00FF 75%, #FF0000 75%, #FF0000 90%, #0000FF 90%, #0000FF 100%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        offlineMessage: {
            background: 'rgba(0,0,0,0.7)',
            padding: '20px',
            textAlign: 'center',
            border: '1px solid #FFFFFF',
            width: '80%',
            maxWidth: '300px'
        },
        offlineText: {
            color: '#FFFFFF',
            fontSize: '1.6rem',
            fontWeight: 'bold',
            fontFamily: "'VT323', monospace"
        },
        footer: {
            height: '24px',
            background: '#000000',
            borderTop: '1px solid rgb(117, 251, 253)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 10px',
            fontSize: '1.4rem'
        },
        footerLeft: {
            color: '#FFFF00'
        },
        footerRight: {
            color: '#FF00FF'
        },
        scanlines: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            background: 'linear-gradient(rgba(255,255,255,0.1) 50%, rgba(0,0,0,0.1) 50%)',
            backgroundSize: '100% 2px',
            opacity: 0.2,
            zIndex: 3
        }
    };

    return (
        <section className="section-tv widget-container" style={styles.section}>
            <h2 className="section-title">
                <span className="section-title-text">TV EN DIRECTO</span>
                <span className="page-number">102</span>
            </h2>
            <div style={styles.outerContainer}>
                <div style={styles.tvContainer}>
                    <div style={styles.tvInner}>
                        <div style={styles.header}>
                            <span style={styles.headerLeft}>P102</span>
                            <span style={styles.headerCenter}>{streamStatus.streamTitle}</span>
                            <span style={styles.headerRight}>{currentTime}</span>
                        </div>

                        <div style={styles.content}>
                            <iframe
                                src={`${TV_URL}/embed/video?initiallyMuted=true`}
                                title="TV EN DIRECTO"
                                style={{
                                    ...styles.iframe,
                                    display: streamStatus.online ? 'block' : 'none'
                                }}
                                allowFullScreen
                                referrerPolicy="origin"
                            />

                            {!streamStatus.online && (
                                <div style={styles.offlineScreen}>
                                    <div style={styles.offlineMessage}>
                                        <div style={styles.offlineText}>
                                            ESLA EKO TV<br/>
                                            * * *<br/>
                                            {getLastDisconnectText()}<br/>
                                            * * *<br/>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div style={styles.footer}>
                            <span style={styles.footerLeft}>
                                {streamStatus.online ? 'EN DIRECTO' : 'FUERA DE EMISIÓN'}
                            </span>
                            <span style={styles.footerRight}>MADRID</span>
                        </div>

                        <div style={styles.scanlines}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RetroTV;