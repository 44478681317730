import React, { useEffect, useState } from 'react';
import './TeletextoHeader.css';

const TeletextoHeader = () => {
  const [hora, setHora] = useState('');
  const [contador, setContador] = useState(0);
  const [fechaDia, setFechaDia] = useState('');

  useEffect(() => {
    const actualizarHora = () => {
      const ahora = new Date();
      const horaActual = ahora.toLocaleTimeString('es-ES');
      setHora(horaActual);
    };

    const actualizarFecha = () => {
      const ahora = new Date();
      const dia = ahora.getDate();
      const mes = ahora.toLocaleDateString('es-ES', { month: 'short' }).toUpperCase();
      const año = ahora.getFullYear();
      const fechaActual = `${dia}-${mes}-${año}`;
      setFechaDia(fechaActual);
    };

    const iniciarContador = () => {
      setInterval(() => {
        setContador(prevContador => (prevContador + 1) % 101);
      }, 200); // Incrementar cada 2 segundos
    };

    actualizarHora();
    actualizarFecha();
    iniciarContador();

    const horaInterval = setInterval(actualizarHora, 1000);

    return () => {
      clearInterval(horaInterval);
    };
  }, []);

  return (
    <div id="teletexto-header">
      <div id="enlace-container">
        <a href="https://eslaeko.net/" target="_blank" rel="noopener noreferrer">P100 | ESLA EKO</a>
      </div>
      <div id="info-container">
        <div className="header-item">101</div>
        <div className="header-item">1/1</div>
        <div className="header-item">{contador}</div>
        <div className="header-item">24h</div>
        <div className="header-item">{fechaDia}</div>
        <div className="header-item">Carabanchel</div>
        <div id="hora" className="header-item">{hora}</div>
      </div>
    </div>
  );
};

export default TeletextoHeader;
